import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="psionic-powers"></a><h2>Psionic Powers
    </h2>
    <p>An <sup>A</sup> appearing at the end of a power&rsquo;s name
in the
power lists denotes an augmentable power. An <sup>X</sup> denotes a
power with an XP component paid by the manifester.</p>
    <a id="power-chains"></a><p><span style={{
        "fontWeight": "bold"
      }}>Power Chains:</span> Some
powers reference other powers that they are based upon. Only
information in a power later in the power chain that is different from
the base power is covered in the power being described. Header entries
and other information that are the same as the base power are not
repeated. The same holds true for powers that are the equivalents of
spells, only the way the power varies from the spell is noted, such as
power point cost.</p>
    <a id="order-of-presentation"></a><p><span style={{
        "fontWeight": "bold"
      }}>Order of Presentation:</span>
In the power lists and the power descriptions that follow them, the
powers are presented in alphabetical order by name-except for those
belonging to certain power chains and those that are psionic
equivalents of spells. When a power&rsquo;s name begins with
&ldquo;lesser,&rdquo;
&ldquo;greater,&rdquo; &ldquo;mass,&rdquo; or a similar kind of
qualifier, the power
description is alphabetized under the second word of the power
description instead. When the effect of a power is essentially the same
as that of a spell, the power&rsquo;s name is simply
&ldquo;Psionic&rdquo; followed by
the name of the spell, and it is alphabetized according to the spell
name.</p>
    <a id="manifester-level"></a><p><span style={{
        "fontWeight": "bold"
      }}>Manifester Level:</span> A
power&rsquo;s effect often depends on the manifester level, which is
the
manifester&rsquo;s psionic class level. A creature with no classes has
a
manifester level equal to its Hit Dice unless otherwise specified. The
word &ldquo;level&rdquo; in the power lists always refers to manifester
level.</p>
    <a id="creatures-and-characters"></a><p><span style={{
        "fontWeight": "bold"
      }}>Creatures and Characters:</span>
&ldquo;Creatures&rdquo; and &ldquo;characters&rdquo; are used
synonymously in the power
descriptions.</p>
    <a id="augment"></a><p><span style={{
        "fontWeight": "bold"
      }}>Augment:</span> Many powers
vary in strength depending on how many power points you put into them.
The more power points you spend, the more powerful the manifestation.
However, you can spend only a total number of points on a power equal
to your manifester level, unless you have an ability that increases
your effective manifester level.</p>
    <p>Many powers can be augmented in more than one way. When the
Augment section contains numbered paragraphs, you need to spend power
points separately for each of the numbered options. When a paragraph in
the Augment section begins with &ldquo;In addition,&rdquo; you gain the
indicated
benefit according to how many power points you have already decided to
spend on manifesting the power.
    </p>
    <h6><span style={{
        "fontStyle": "italic"
      }}>A-Z Index</span> </h6>
    <table style={{
      "width": "100%"
    }} className="spell-list" border="0" cellPadding="2" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "20%"
          }}><span style={{
              "fontStyle": "italic"
            }}>Power</span></th>
          <th style={{
            "width": "12%"
          }}>Discipline</th>
          <td style={{
            "verticalAlign": "top"
          }}><span style={{
              "fontWeight": "bold"
            }}>Sub-discipline</span>
          </td>
          <th style={{
            "width": "17%"
          }}>Descriptor</th>
          <th style={{
            "width": "25%"
          }}>Level</th>
          <th style={{
            "width": "15%"
          }}>Display</th>
        </tr>
        <tr>
          <th colSpan="6">
            <h3><a id="a"></a>A</h3>
          </th>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#adapt-body" style={{
              "color": "rgb(87, 158, 182)"
            }}>Adapt Body</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 5, psychic warrior 5
          </td>
          <td>Visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#affinity-field" style={{
              "color": "rgb(87, 158, 182)"
            }}>Affinity Field</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 9
          </td>
          <td>Material and visual
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#anchored-navigation" style={{
              "color": "rgb(87, 158, 182)"
            }}>Anchored Navigation</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Seer 4
          </td>
          <td>Material and olfactory
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#animal-affinity" style={{
              "color": "rgb(87, 158, 182)"
            }}>Animal Affinity</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Egoist 2, psychic warrior 2</td>
          <td>Material
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#apopsi" style={{
              "color": "rgb(87, 158, 182)"
            }}>Apopsi</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 9
          </td>
          <td>Auditory, material, and visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#assimilate" style={{
              "color": "rgb(87, 158, 182)"
            }}>Assimilate</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>&nbsp;Psion/wilder 9
          </td>
          <td>Auditory and visual
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#astral-caravan" style={{
              "color": "rgb(87, 158, 182)"
            }}>Astral Caravan</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Nomad 3
          </td>
          <td>None
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#astral-construct" style={{
              "color": "rgb(87, 158, 182)"
            }}>Astral Construct</a>
          </td>
          <td>Metacreativity
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Creation</td>
          <td>
          </td>
          <td>Shaper 1
          </td>
          <td>Visual; see text
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#astral-seed" style={{
              "color": "rgb(87, 158, 182)"
            }}>Astral Seed</a>
          </td>
          <td>Metacreativity
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Shaper 8
          </td>
          <td>Material; see text
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#astral-traveler" style={{
              "color": "rgb(87, 158, 182)"
            }}>Astral Traveler</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 1, psychic warrior 1
          </td>
          <td>None
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#attraction" style={{
              "color": "rgb(87, 158, 182)"
            }}>Attraction</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Charm
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 1
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#aura-alteration" style={{
              "color": "rgb(87, 158, 182)"
            }}>Aura Alteration</a>
          </td>
          <td>Telepathy 
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 6
          </td>
          <td>Material
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#aura-sight" style={{
              "color": "rgb(87, 158, 182)"
            }}>Aura Sight</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 4
          </td>
          <td>Visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#aversion" style={{
              "color": "rgb(87, 158, 182)"
            }}>Aversion</a>
          </td>
          <td>Telepathy 
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Compulsion
          </td>
          <td>Mind-Affecting
          </td>
          <td>Telepath 2
          </td>
          <td>Auditory and material
          </td>
        </tr>
        <tr>
          <th colSpan="6">
            <h3><a id="b"></a>B</h3>
          </th>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#baleful-teleport" style={{
              "color": "rgb(87, 158, 182)"
            }}>Baleful Teleport</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Teleportation
          </td>
          <td>
          </td>
          <td>Nomad 5
          </td>
          <td>Material and visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#banishment-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Banishment, Psionic</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Nomad 6
          </td>
          <td>Auditory and material
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#bend-reality" style={{
              "color": "rgb(87, 158, 182)"
            }}>Bend Reality</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 8
          </td>
          <td>Visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#bestow-power" style={{
              "color": "rgb(87, 158, 182)"
            }}>Bestow Power</a>
          </td>
          <td>Telepathy 
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 2
          </td>
          <td>Mental
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#biofeedback" style={{
              "color": "rgb(87, 158, 182)"
            }}>Biofeedback</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 2, psychic warrior 1
          </td>
          <td>Material and visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#bite-of-the-wolf" style={{
              "color": "rgb(87, 158, 182)"
            }}>Bite of the Wolf</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psychic warrior 1
          </td>
          <td>Visual; see text
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#body-adjustment" style={{
              "color": "rgb(87, 158, 182)"
            }}>Body Adjustment</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Healing
          </td>
          <td>
          </td>
          <td>Psion/wilder 3, psychic warrior 2
          </td>
          <td>Auditory and material
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#body-equilibrium" style={{
              "color": "rgb(87, 158, 182)"
            }}>Body Equilibrium</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 2, psychic warrior 2
          </td>
          <td>Material and visual
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#body-purification" style={{
              "color": "rgb(87, 158, 182)"
            }}>Body Purification</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Healing
          </td>
          <td>
          </td>
          <td>Psion/wilder 3, psychic warrior 2
          </td>
          <td>Auditory and material
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#bolt" style={{
              "color": "rgb(87, 158, 182)"
            }}>Bolt</a>
          </td>
          <td>Metacreativity
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Creation
          </td>
          <td>
          </td>
          <td>Psion/wilder 1
          </td>
          <td>Material
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#brain-lock" style={{
              "color": "rgb(87, 158, 182)"
            }}>Brain Lock</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Compulsion
          </td>
          <td>Mind-Affecting
          </td>
          <td>Telepath 2
          </td>
          <td>Material and visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#breath-of-the-black-dragon" style={{
              "color": "rgb(87, 158, 182)"
            }}>Breath of the Black Dragon</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Acid
          </td>
          <td>Psion/wilder 6, psychic warrior 6
          </td>
          <td>Visual
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#burst" style={{
              "color": "rgb(87, 158, 182)"
            }}>Burst</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Nomad 1, psychic warrior 1
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr>
          <th colSpan="6">
            <h3><a id="c"></a>C</h3>
          </th>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#call-to-mind" style={{
              "color": "rgb(87, 158, 182)"
            }}>Call to Mind</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 1
          </td>
          <td>Mental
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#call-weaponry" style={{
              "color": "rgb(87, 158, 182)"
            }}>Call Weaponry</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>Teleportation
          </td>
          <td>
          </td>
          <td>Psychic warrior 1
          </td>
          <td>Material
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#Catapsi" style={{
              "color": "rgb(87, 158, 182)"
            }}>Catapsi</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 5, psychic warrior 5
          </td>
          <td>Mental and visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#catfall" style={{
              "color": "rgb(87, 158, 182)"
            }}>Catfall</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 1, psychic warrior 1
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#chameleon" style={{
              "color": "rgb(87, 158, 182)"
            }}>Chameleon</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>
          </td>
          <td>Egoist 2, psychic warrior 1
          </td>
          <td>Olfactory
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#charm-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Charm, Psionic</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>Charm
          </td>
          <td>Mind-Affecting
          </td>
          <td>Telepath 1
          </td>
          <td>Mental
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#clairtangent-hand" style={{
              "color": "rgb(87, 158, 182)"
            }}>Clairtangent Hand</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>Scrying
          </td>
          <td>
          </td>
          <td>Seer 5
          </td>
          <td>Auditory, mental, and visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#clairvoyant-sense" style={{
              "color": "rgb(87, 158, 182)"
            }}>Clairvoyant Sense</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>Scrying
          </td>
          <td>
          </td>
          <td>Seer 2
          </td>
          <td>Auditory and visual
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#claw-of-energy" style={{
              "color": "rgb(87, 158, 182)"
            }}>Claw of Energy</a>
          </td>
          <td>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>see text
          </td>
          <td>Psychic warrior 4
          </td>
          <td>Visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#claws-of-the-beast" style={{
              "color": "rgb(87, 158, 182)"
            }}>Claws of the Beast</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>
          </td>
          <td>Psychic warrior 1
          </td>
          <td>Visual
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#claws-of.the-vampire" style={{
              "color": "rgb(87, 158, 182)"
            }}>Claws of the Vampire</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>
          </td>
          <td>Psychic warrior 3
          </td>
          <td>Material and visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#cloud-mind" style={{
              "color": "rgb(87, 158, 182)"
            }}>Cloud Mind</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 2
          </td>
          <td>None
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#cloud-mind-mass" style={{
              "color": "rgb(87, 158, 182)"
            }}>Cloud Mind, Mass</a>
          </td>
          <td>Telepathy</td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>Mind-Affecting</td>
          <td>Psion/wilder 6
          </td>
          <td>None
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#compression" style={{
              "color": "rgb(87, 158, 182)"
            }}>Compression</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>
          </td>
          <td>Psychic warrior 1
          </td>
          <td>Olfactory
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#conceal-thoughts" style={{
              "color": "rgb(87, 158, 182)"
            }}>Conceal Thoughts</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 1, psychic warrior 1
          </td>
          <td>Mental
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#concealing-amorpha" style={{
              "color": "rgb(87, 158, 182)"
            }}>Concealing Amorpha</a>
          </td>
          <td>Metacreativity
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>Creation
          </td>
          <td>
          </td>
          <td>Psion/wilder 2, psychic warrior 2
          </td>
          <td>Material; see text
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#concealing-amorpha-greater" style={{
              "color": "rgb(87, 158, 182)"
            }}>Concealing Amorpha,&nbsp; Greater</a>
          </td>
          <td>Metacreativity
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>Creation
          </td>
          <td>
          </td>
          <td>Shaper 3, psychic warrior 3
          </td>
          <td>Material; see text
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#concussion-blast" style={{
              "color": "rgb(87, 158, 182)"
            }}>Concussion Blast</a>
          </td>
          <td>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>Force
          </td>
          <td>Psion/wilder 2
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#contingency-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Contingency, Psionic</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 6
          </td>
          <td>Olfactory
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#control-air" style={{
              "color": "rgb(87, 158, 182)"
            }}>Control Air</a>
          </td>
          <td>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>
          </td>
          <td>Kineticist 2
          </td>
          <td>Mental
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#control-body" style={{
              "color": "rgb(87, 158, 182)"
            }}>Control Body</a>
          </td>
          <td>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>
          </td>
          <td>Kineticist 4
          </td>
          <td>Material
          </td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="psionicPowersAtoC.html#">Control
Flames</a>
          </td>
          <td>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>Fire
          </td>
          <td>Psion/wilder 1
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#control-light" style={{
              "color": "rgb(87, 158, 182)"
            }}>Control Light</a>
          </td>
          <td>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>Light
          </td>
          <td>Psion/wilder 1
          </td>
          <td>Visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#control-object" style={{
              "color": "rgb(87, 158, 182)"
            }}>Control Object</a>
          </td>
          <td>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>
          </td>
          <td>Kineticist 1
          </td>
          <td>Material
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#control-sound" style={{
              "color": "rgb(87, 158, 182)"
            }}>Control Sound</a>
          </td>
          <td>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>Sonic
          </td>
          <td>Psion/wilder 2
          </td>
          <td>Auditory; see text
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#co-opt-concentration" style={{
              "color": "rgb(87, 158, 182)"
            }}>Co-Opt Concentration</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>Compulsion
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 6
          </td>
          <td>Mental
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#correspond" style={{
              "color": "rgb(87, 158, 182)"
            }}>Correspond</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 4
          </td>
          <td>Mental
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#create-sound" style={{
              "color": "rgb(87, 158, 182)"
            }}>Create Sound</a>
          </td>
          <td>Metacreativity
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Creation
          </td>
          <td>Sonic
          </td>
          <td>Psion/wilder 1
          </td>
          <td>Auditory; see text
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#crisis-of-breath" style={{
              "color": "rgb(87, 158, 182)"
            }}>Crisis of Breath</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Compulsion
          </td>
          <td>Mind-Affecting
          </td>
          <td>Telepath 3
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#crisis-of-life" style={{
              "color": "rgb(87, 158, 182)"
            }}>Crisis of Life</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting, Death
          </td>
          <td>Telepath 7
          </td>
          <td>Mental
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#crystal-shard" style={{
              "color": "rgb(87, 158, 182)"
            }}>Crystal Shard</a>
          </td>
          <td>Metacreativity
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Creation
          </td>
          <td>
          </td>
          <td>Psion/wilder 1
          </td>
          <td>Auditory and material
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#crystallize" style={{
              "color": "rgb(87, 158, 182)"
            }}>Crystallize</a>
          </td>
          <td>Metacreativity
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Shaper 6
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr>
          <th colSpan="6">
            <h3><a id="d"></a>D</h3>
          </th>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#danger-sense" style={{
              "color": "rgb(87, 158, 182)"
            }}>Danger Sense</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 3, psychic warrior 3
          </td>
          <td>Visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#darksvision-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Darkvision, Psionic</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 3, psychic warrior 2
          </td>
          <td>Visual
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#daze-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Daze, Psionic</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Compulsion
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 1
          </td>
          <td>Material and mental
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#death-urge" style={{
              "color": "rgb(87, 158, 182)"
            }}>Death Urge</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Compulsion
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 4
          </td>
          <td>Mental
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#deceleration" style={{
              "color": "rgb(87, 158, 182)"
            }}>Deceleration</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 1
          </td>
          <td>Auditory and material
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#decerebrate" style={{
              "color": "rgb(87, 158, 182)"
            }}>Decerebrate</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Teleportation</td>
          <td>
          </td>
          <td>Psion/wilder 7
          </td>
          <td>Mental
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#deja-vu" style={{
              "color": "rgb(87, 158, 182)"
            }}>D&eacute;j&agrave; Vu</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 1
          </td>
          <td>Mental
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#demoralize" style={{
              "color": "rgb(87, 158, 182)"
            }}>Demoralize</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 1
          </td>
          <td>Mental and olfactory
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#destiny-dissonance" style={{
              "color": "rgb(87, 158, 182)"
            }}>Destiny Dissonance</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Seer 1
          </td>
          <td>Material and mental
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#detect-hostile-intent" style={{
              "color": "rgb(87, 158, 182)"
            }}>Detect Hostile Intent</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 2, psychic warrior 2
          </td>
          <td>Olfactory
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#detect-psionics" style={{
              "color": "rgb(87, 158, 182)"
            }}>Detect Psionics</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 1, psychic warrior 1
          </td>
          <td>Auditory and visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#detect-remote-viewing" style={{
              "color": "rgb(87, 158, 182)"
            }}>Detect Remote Viewing</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 4
          </td>
          <td>Mental and visual
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#detect-teleportation" style={{
              "color": "rgb(87, 158, 182)"
            }}>Detect Teleportation</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Nomad 1
          </td>
          <td>Visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#dimension-door-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Dimension Door, Psionic</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Teleportation</td>
          <td>
          </td>
          <td>Psion/wilder 4, psychic warrior 4
          </td>
          <td>Visual
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#dimension-slide" style={{
              "color": "rgb(87, 158, 182)"
            }}>Dimension Slide</a>
          </td>
          <td>Psychoportation 
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Teleportation
          </td>
          <td>
          </td>
          <td>Psychic warrior 3
          </td>
          <td>Visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#dimension-swap" style={{
              "color": "rgb(87, 158, 182)"
            }}>Dimension Swap</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Teleportation
          </td>
          <td>
          </td>
          <td>Nomad 2, psychic warrior 2
          </td>
          <td>Visual
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#dimensional-anchor-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Dimensional Anchor, Psionic</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Nomad 4
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#disable" style={{
              "color": "rgb(87, 158, 182)"
            }}>Disable</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Compulsion
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 1
          </td>
          <td>Visual
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#disintegrate-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Disintegrate, Psionic</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 6
          </td>
          <td>Auditory, material, and visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#dismissal-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Dismissal, Psionic</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Nomad 4
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#dismiss-ectoplasm" style={{
              "color": "rgb(87, 158, 182)"
            }}>Dismiss Ectoplasm</a>
          </td>
          <td>Metacreativity
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 3
          </td>
          <td>Auditory and visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#dispel-psionics" style={{
              "color": "rgb(87, 158, 182)"
            }}>Dispel Psionics</a>
          </td>
          <td>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 3
          </td>
          <td>Visual
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#dispelling-buffer" style={{
              "color": "rgb(87, 158, 182)"
            }}>Dispelling Buffer</a>
          </td>
          <td>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Kineticist 6, psychic warrior 6
          </td>
          <td>Material and olfactory
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#dissipating-touch" style={{
              "color": "rgb(87, 158, 182)"
            }}>Dissipating Touch</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Teleportation
          </td>
          <td>
          </td>
          <td>Psion/wilder 1, psychic warrior 1
          </td>
          <td>Auditory and visual
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#dissolving-touch" style={{
              "color": "rgb(87, 158, 182)"
            }}>Dissolving Touch</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Acid
          </td>
          <td>Psychic warrior 2
          </td>
          <td>Visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#dissolving-weapon" style={{
              "color": "rgb(87, 158, 182)"
            }}>Dissolving Weapon</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Acid
          </td>
          <td>Psychic warrior 2
          </td>
          <td>Visual
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#distract" style={{
              "color": "rgb(87, 158, 182)"
            }}>Distract</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 1, psychic warrior 1
          </td>
          <td>Mental
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#divert-teleportation" style={{
              "color": "rgb(87, 158, 182)"
            }}>Divert Teleportation</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Teleportation
          </td>
          <td>
          </td>
          <td>Psion/wilder 7
          </td>
          <td>Visual
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#divination-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Divination, Psionic</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 4
          </td>
          <td>Mental and visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#dominate-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Dominate, Psionic</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Compulsion
          </td>
          <td>Mind-Affecting
          </td>
          <td>Telepath 4
          </td>
          <td>Mental
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#dream-travel" style={{
              "color": "rgb(87, 158, 182)"
            }}>Dream Travel</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Nomad 7
          </td>
          <td>Visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#duodimensional-claw" style={{
              "color": "rgb(87, 158, 182)"
            }}>Duodimensional Claw</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psychic warrior 3
          </td>
          <td>Material
          </td>
        </tr>
        <tr>
          <th colSpan="6">
            <h3><a id="e"></a>E</h3>
          </th>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#ecto-protection" style={{
              "color": "rgb(87, 158, 182)"
            }}>Ecto Protection</a>
          </td>
          <td>Metacreativity
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 1
          </td>
          <td>Visual; see text
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#ectoplasmic-cocoon" style={{
              "color": "rgb(87, 158, 182)"
            }}>Ectoplasmic Cocoon</a>
          </td>
          <td>Metacreativity
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>
          </td>
          <td>Shaper 3
          </td>
          <td>Auditory and material
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#ectoplasmic-cocoon-mass" style={{
              "color": "rgb(87, 158, 182)"
            }}>Ectoplasmi Cocoon, Mass</a>
          </td>
          <td>Metacreativity
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>
          </td>
          <td>Shaper 7
          </td>
          <td>Auditory and material</td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#ectoplasmic-form" style={{
              "color": "rgb(87, 158, 182)"
            }}>Ectoplasmic Form</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>
          </td>
          <td>Egoist 3, psychic warrior 3
          </td>
          <td>Olfactory
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#ectoplasmic-shambler" style={{
              "color": "rgb(87, 158, 182)"
            }}>Ectoplasmic Shambler</a>
          </td>
          <td>Metacreativity 
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>Creation
          </td>
          <td>
          </td>
          <td>Psion/wilder 5
          </td>
          <td>Auditory, material, and olfactory; see text
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#ego-whip" style={{
              "color": "rgb(87, 158, 182)"
            }}>Ego Whip</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 2
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#elfsight" style={{
              "color": "rgb(87, 158, 182)"
            }}>Elfsight</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 2, psychic warrior 1
          </td>
          <td>Visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#empathic-feedback" style={{
              "color": "rgb(87, 158, 182)"
            }}>Empathic Feedback</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 4, psychic warrior 3
          </td>
          <td>Auditory and material
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#empathic-transfer" style={{
              "color": "rgb(87, 158, 182)"
            }}>Empathic Transfer</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>
          </td>
          <td>Egoist 2, psychic warrior 2
          </td>
          <td>Auditory and material
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#empathic-transfer-hostile" style={{
              "color": "rgb(87, 158, 182)"
            }}>Empathic Transfer, Hostile</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Telepath 3, psychic warrior 3
          </td>
          <td>Auditory and material
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#empathy" style={{
              "color": "rgb(87, 158, 182)"
            }}>Empathy</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 1
          </td>
          <td>Mental
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#empty-mind" style={{
              "color": "rgb(87, 158, 182)"
            }}>Empty Mind</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 1, psychic warrior 1
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#energy-adaptation" style={{
              "color": "rgb(87, 158, 182)"
            }}>Energy Adaptation</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>see text
          </td>
          <td>Psion/wilder 4, psychic warrior 4
          </td>
          <td>Visual; see text
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#energy-adaptation-specified" style={{
              "color": "rgb(87, 158, 182)"
            }}>Energy Adaptation, Specified</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>see text
          </td>
          <td>Psion/wilder 2, psychic warrior 2
          </td>
          <td>Visual; see text</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#energy-ball" style={{
              "color": "rgb(87, 158, 182)"
            }}>Energy Ball</a>
          </td>
          <td>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>see text
          </td>
          <td>Kineticist 4
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#energy-bolt" style={{
              "color": "rgb(87, 158, 182)"
            }}>Energy Bolt</a>
          </td>
          <td>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>see text
          </td>
          <td>Psion/wilder 3
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#energy-burst" style={{
              "color": "rgb(87, 158, 182)"
            }}>Energy Burst</a>
          </td>
          <td>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>see text
          </td>
          <td>Psion/wilder 3
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#energy-cone" style={{
              "color": "rgb(87, 158, 182)"
            }}>Energy Cone</a>
          </td>
          <td>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td>see text
          </td>
          <td>Kineticist 3
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}><a href="psionicPowersDtoF.html#energy-conversion" style={{
              "color": "rgb(87, 158, 182)"
            }}>Energy Conversion</a>
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>see
text
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Psion/wilder
7
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Mental
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}><a href="psionicPowersDtoF.html#energy-current" style={{
              "color": "rgb(87, 158, 182)"
            }}>Energy Current</a>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>see text
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Kineticist 5
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Visual; see text
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}><a href="psionicPowersDtoF.html#energy-missile" style={{
              "color": "rgb(87, 158, 182)"
            }}>Energy Missile</a>
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>see
text
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Kineticist
2
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Auditory
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}><a href="psionicPowersDtoF.html#energy-push" style={{
              "color": "rgb(87, 158, 182)"
            }}>Energy Push</a>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>see text
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Psion/wilder 2
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Auditory and visual
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}><a href="psionicPowersDtoF.html#energy-ray" style={{
              "color": "rgb(87, 158, 182)"
            }}>Energy Ray</a>
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>see
text
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Psion/wilder
1
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Auditory
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}><a href="psionicPowersDtoF.html#energy-retort" style={{
              "color": "rgb(87, 158, 182)"
            }}>Energy Retort</a>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>see text
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Psion/wilder 3
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Visual
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}><a href="psionicPowersDtoF.html#energy-stun" style={{
              "color": "rgb(87, 158, 182)"
            }}>Energy Stun</a>
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>see
text
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Psion/wilder
2
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Auditory
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}><a href="psionicPowersDtoF.html#energy-wall" style={{
              "color": "rgb(87, 158, 182)"
            }}>Energy Wall</a>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Metacreativity
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Creation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>see text
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Psion/wilder 3
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Auditory
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}><a href="psionicPowersDtoF.html#energy-wave" style={{
              "color": "rgb(87, 158, 182)"
            }}>Energy Wave</a>
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>see
text
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Psion/wilder
7
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Auditory
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}><a href="psionicPowersDtoF.html#entangling-ectoplasm" style={{
              "color": "rgb(87, 158, 182)"
            }}>Entangling Ectoplasm</a>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Metacreativity
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Creation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Psion/wilder 1
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Material and visual
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}><a href="psionicPowersDtoF.html#eradicate-invisibility" style={{
              "color": "rgb(87, 158, 182)"
            }}>Eradicate Invisibility</a>
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Psion/wilder
3
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Visual
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}><a href="psionicPowersDtoF.html#escape-detection" style={{
              "color": "rgb(87, 158, 182)"
            }}>Escape Detection</a>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Psychic warrior 3, seer 3
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>None
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}><a href="psionicPowersDtoF.html#ethereal-jaunt-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Ethereal Jaunt, Psionic</a>
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Nomad
7
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Visual
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}><a href="psionicPowersDtoF.html#etherealness-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Etherealness, Psionic</a>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Psion/wilder 9
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}><a href="psionicPowersDtoF.html#evade-burst" style={{
              "color": "rgb(87, 158, 182)"
            }}>Evade Burst</a>
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Psion/wilder
7, psychic warrior 3
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Material
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}><a href="psionicPowersDtoF.html#exhalation-of-the-black-dragon" style={{
              "color": "rgb(87, 158, 182)"
            }}>Exhalation of the Black Dragon</a>
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>Acid
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>Psychic warrior 3
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>Visual
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}><a href="psionicPowersDtoF.html#expansion" style={{
              "color": "rgb(87, 158, 182)"
            }}>Expansion</a>
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Psychic
warrior 1
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Olfactory
          </td>
        </tr>
        <tr>
          <th colSpan="6">
            <h3><a id="f"></a>F</h3>
          </th>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#fabricate-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Fabricate, Psionic</a>
          </td>
          <td>Metacreativity
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Creation
          </td>
          <td>
          </td>
          <td>Shaper 4
          </td>
          <td>Material
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#fabricate-greater-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Fabricate, Greater Psionic</a>
          </td>
          <td>Metacreativity
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Creation
          </td>
          <td>
          </td>
          <td>Shaper 6
          </td>
          <td>Material
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#false-sensory-input" style={{
              "color": "rgb(87, 158, 182)"
            }}>False Sensory Input</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Telepath 3
          </td>
          <td>Mental
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#far-hand" style={{
              "color": "rgb(87, 158, 182)"
            }}>Far Hand</a>
          </td>
          <td>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 1
          </td>
          <td>Visual
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#fate-link" style={{
              "color": "rgb(87, 158, 182)"
            }}>Fate Link</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Seer 3
          </td>
          <td>Olfactory
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#fate-of-one" style={{
              "color": "rgb(87, 158, 182)"
            }}>Fate of One</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Seer 7
          </td>
          <td>Mental and visual
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#feat-leech" style={{
              "color": "rgb(87, 158, 182)"
            }}>Feat Leech</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 2, psychic warrior 2
          </td>
          <td>Mental and visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#fiery-discorporation" style={{
              "color": "rgb(87, 158, 182)"
            }}>Fiery Discorporation</a>
          </td>
          <td>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Fire
          </td>
          <td>Kineticist 5
          </td>
          <td>Visual
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#fission" style={{
              "color": "rgb(87, 158, 182)"
            }}>Fission</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Egoist 7
          </td>
          <td>Olfactory
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#float" style={{
              "color": "rgb(87, 158, 182)"
            }}>Float</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 1, psychic warrior 1
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#fly-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Fly, Psionic</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Nomad 4
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#force-screen" style={{
              "color": "rgb(87, 158, 182)"
            }}>Force Screen</a>
          </td>
          <td>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Force
          </td>
          <td>Psion/wilder 1, psychic warrior 1
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#form-of-doom" style={{
              "color": "rgb(87, 158, 182)"
            }}>Form of Doom</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psychic warrior 6
          </td>
          <td>Visual; see text
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#freedom-of-movement-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Freedom of Movement, Psionic</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 4, psychic warrior 4
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#fuse-flesh" style={{
              "color": "rgb(87, 158, 182)"
            }}>Fuse Flesh</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 6
          </td>
          <td>Visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#fusion" style={{
              "color": "rgb(87, 158, 182)"
            }}>Fusion</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Egoist 8
          </td>
          <td>Auditory, material, and visual
          </td>
        </tr>
        <tr>
          <th colSpan="6">
            <h3><a id="g"></a>G</h3>
          </th>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#genesis" style={{
              "color": "rgb(87, 158, 182)"
            }}>Genesis</a>
          </td>
          <td>Metacreativity
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Creation
          </td>
          <td>
          </td>
          <td>&nbsp;Shaper 9
          </td>
          <td>Material
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#graft-weapon" style={{
              "color": "rgb(87, 158, 182)"
            }}>Graft Weapon</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psychic warrior 3
          </td>
          <td>Olfactory
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#grease-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Grease, Psionic</a>
          </td>
          <td>Metacreativity 
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Creation
          </td>
          <td>
          </td>
          <td>Psion/wilder 1
          </td>
          <td>Visual and olfactory
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#grip-of-iron" style={{
              "color": "rgb(87, 158, 182)"
            }}>Grip of Iron</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psychic warrior 1
          </td>
          <td>Visual
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }} rowSpan="1" colSpan="6">
            <h3><a id="h"></a>H</h3>
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#hail-of-crystals" style={{
              "color": "rgb(87, 158, 182)"
            }}>Hail of Crystals</a>
          </td>
          <td>Metacreativity
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Creation
          </td>
          <td>
          </td>
          <td>Shaper 5
          </td>
          <td>Auditory and visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#hammer" style={{
              "color": "rgb(87, 158, 182)"
            }}>Hammer</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 1, psychic warrior 1
          </td>
          <td>Auditory and material
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#hustle" style={{
              "color": "rgb(87, 158, 182)"
            }}>Hustle</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Egoist 3, psychic warrior 2
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#hypercognition" style={{
              "color": "rgb(87, 158, 182)"
            }}>Hypercognition</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Seer 8
          </td>
          <td>Mental
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }} rowSpan="1" colSpan="6">
            <h3><a id="i"></a>I</h3>
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#id-insinuation" style={{
              "color": "rgb(87, 158, 182)"
            }}>Id Insinuation</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Compulsion
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 2
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#identify-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Identify, Psionic</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 2
          </td>
          <td>Material and mental
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#immovability" style={{
              "color": "rgb(87, 158, 182)"
            }}>Immovability</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psychic warrior 4
          </td>
          <td>Visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#incarnate" style={{
              "color": "rgb(87, 158, 182)"
            }}>Incarnate</a>
          </td>
          <td>Metacreativity
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 5
          </td>
          <td>Material
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#inertial-armor" style={{
              "color": "rgb(87, 158, 182)"
            }}>Inertial Armor</a>
          </td>
          <td>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 1, psychic warrior 1
          </td>
          <td>Visual; see text
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#inertial-barrier" style={{
              "color": "rgb(87, 158, 182)"
            }}>Inertial Barrier</a>
          </td>
          <td>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Kineticist 4, psychic warrior 4
          </td>
          <td>Auditory and mental
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#inflict-pain" style={{
              "color": "rgb(87, 158, 182)"
            }}>Inflict Pain</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 2
          </td>
          <td>Mental
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#insanity" style={{
              "color": "rgb(87, 158, 182)"
            }}>Insanity</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Compulsion
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 7
          </td>
          <td>Mental
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#intellect-fortress" style={{
              "color": "rgb(87, 158, 182)"
            }}>Intellect Fortress</a>
          </td>
          <td>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 4
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#iron-body-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Iron Body, Psionic</a>
          </td>
          <td>Metacreativity
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Creation
          </td>
          <td>
          </td>
          <td>Psion/wilder 8
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }} rowSpan="1" colSpan="6">
            <h3><a id="k"></a>K</h3>
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#keen-edge-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Keen Edge, Psionic</a>
          </td>
          <td>Metacreativity
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 3, psychic warrior 3
          </td>
          <td>Visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#knock-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Knock, Psionic</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 2
          </td>
          <td>Material
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#know-direction-and-location" style={{
              "color": "rgb(87, 158, 182)"
            }}>Know Direction and Location</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 1
          </td>
          <td>Mental
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }} rowSpan="1" colSpan="6">
            <h3><a id="l"></a>L</h3>
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#leech-field" style={{
              "color": "rgb(87, 158, 182)"
            }}>Leech Field</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 5
          </td>
          <td>Visual; see text
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#levitate-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Levitate, Psionic</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Nomad 2, psion/wilder 2, psychic warrior 2
          </td>
          <td>Olfactory
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }} rowSpan="1" colSpan="6">
            <h3><a id="m"></a>M</h3>
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#major-creation-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Major Creation, Psionic</a>
          </td>
          <td>Metacreativity
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Creation
          </td>
          <td>
          </td>
          <td>Psion/wilder 5
          </td>
          <td>None
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#matter-agitation" style={{
              "color": "rgb(87, 158, 182)"
            }}>Matter Agitation</a>
          </td>
          <td>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 1
          </td>
          <td>Auditory and material
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#matter-manipulation" style={{
              "color": "rgb(87, 158, 182)"
            }}>Matter Manipulation</a>
          </td>
          <td>Metacreativity
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 8
          </td>
          <td>Auditory and mental
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#mental-barrier" style={{
              "color": "rgb(87, 158, 182)"
            }}>Mental Barrier</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 3, psychic warrior 3
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#mental-disruption" style={{
              "color": "rgb(87, 158, 182)"
            }}>Mental Disruption</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 2
          </td>
          <td>Mental
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#metaconcert" style={{
              "color": "rgb(87, 158, 182)"
            }}>Metaconcert</a>
          </td>
          <td>Telepathy 
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psychic warrior 5, telepath 5
          </td>
          <td>Visual; see text
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#metafaculty" style={{
              "color": "rgb(87, 158, 182)"
            }}>Metafaculty</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Seer 9
          </td>
          <td>Mental, olfactory, and visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#metamorphosis" style={{
              "color": "rgb(87, 158, 182)"
            }}>Metamorphosis</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Egoist 4
          </td>
          <td>Material and olfactory
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#metamorphosis-greater" style={{
              "color": "rgb(87, 158, 182)"
            }}>Metamorphosis, Greater</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Egoist 9
          </td>
          <td>Material and visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#metaphysical-claw" style={{
              "color": "rgb(87, 158, 182)"
            }}>Metaphysical Claw</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psychic warrior 1
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#metaphysical-weapon" style={{
              "color": "rgb(87, 158, 182)"
            }}>Metaphysical Weapon</a>
          </td>
          <td>Metacreativity
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psychic warrior 1
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#microcosm" style={{
              "color": "rgb(87, 158, 182)"
            }}>Microcosm</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Compulsion
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 9
          </td>
          <td>Material
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#mind-blank-personal" style={{
              "color": "rgb(87, 158, 182)"
            }}>Mind Blank, Personal</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 7, psychic warrior 6
          </td>
          <td>Olfactory
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#mind-blank-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Mind Blank, Psionic</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 8
          </td>
          <td>Olfactory
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#mind-probe" style={{
              "color": "rgb(87, 158, 182)"
            }}>Mind Probe</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>Charm
          </td>
          <td>Mind-Affecting
          </td>
          <td>Telepath 5
          </td>
          <td>Auditory, material, and visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#mind-seed" style={{
              "color": "rgb(87, 158, 182)"
            }}>Mind Seed</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Compulsion
          </td>
          <td>Evil, Mind-Affecting
          </td>
          <td>Telepath 8
          </td>
          <td>Mental
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#mind-switch" style={{
              "color": "rgb(87, 158, 182)"
            }}>Mind Switch</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Telepath 6
          </td>
          <td>Visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#mind-switch-true" style={{
              "color": "rgb(87, 158, 182)"
            }}>Mind Switch, True</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Telepath 9
          </td>
          <td>Visual
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#mind-thrust" style={{
              "color": "rgb(87, 158, 182)"
            }}>Mind Thrust</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 1
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#mind-trap" style={{
              "color": "rgb(87, 158, 182)"
            }}>Mind Trap</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 3
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#mindlink" style={{
              "color": "rgb(87, 158, 182)"
            }}>Mindlink</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Telepath 1
          </td>
          <td>Mental
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#mindlink-thieveing" style={{
              "color": "rgb(87, 158, 182)"
            }}>Mindlink, Thieving</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Telepath 4
          </td>
          <td>Mental
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#mindwipe" style={{
              "color": "rgb(87, 158, 182)"
            }}>Mindwipe</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 4
          </td>
          <td>Auditory, mental, and visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#minor-creation-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Minor Creation, Psionic</a>
          </td>
          <td>Metacreativity
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Creation
          </td>
          <td>
          </td>
          <td>Shaper 1
          </td>
          <td>Material
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#missive" style={{
              "color": "rgb(87, 158, 182)"
            }}>Missive</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting, Language-Dependent
          </td>
          <td>Psion/wilder 1
          </td>
          <td>Mental
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#missive-mass" style={{
              "color": "rgb(87, 158, 182)"
            }}>Missive, Mass</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting, Language-Dependent
          </td>
          <td>Psion/wilder 2
          </td>
          <td>Mental
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#modify-memory-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Modify Memory, Psionic</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Compulsion
          </td>
          <td>Mind-Affecting
          </td>
          <td>Telepath 4
          </td>
          <td>Mental
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#moment-of-prescience-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Moment of Prescience, Psionic</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 7
          </td>
          <td>Visual
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#my-light" style={{
              "color": "rgb(87, 158, 182)"
            }}>My Light</a>
          </td>
          <td>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Light
          </td>
          <td>Psion/wilder 1, psychic warrior 1
          </td>
          <td>Auditory and visual
          </td>
        </tr>
        <tr>
          <td rowSpan="1" colSpan="6">
            <h3><a id="n"></a>N</h3>
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#null-psionic-field" style={{
              "color": "rgb(87, 158, 182)"
            }}>Null Psionic Field</a>
          </td>
          <td>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Kineticist 6
          </td>
          <td>Auditory and visual
          </td>
        </tr>
        <tr>
          <td rowSpan="1" colSpan="6">
            <h3><a id="o"></a>O</h3>
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#oak-body" style={{
              "color": "rgb(87, 158, 182)"
            }}>Oak Body</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 7, psychic warrior 5
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#object-reading" style={{
              "color": "rgb(87, 158, 182)"
            }}>Object Reading</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Seer 2
          </td>
          <td>Auditory and material
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#overland-flight-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Overland Flight, Psionic</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 6
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr>
          <td rowSpan="1" colSpan="6">
            <h3><a id="p"></a>P</h3>
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#painful-strike" style={{
              "color": "rgb(87, 158, 182)"
            }}>Painful Strike</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psychic warrior 2
          </td>
          <td>Material and visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#personality-parasite" style={{
              "color": "rgb(87, 158, 182)"
            }}>Personality Parasite</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 4
          </td>
          <td>Auditory and visual
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#phase-door-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Phase Door, Psionic</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 7
          </td>
          <td>Visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#plane-shift-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Plane Shift, Psionic</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 5
          </td>
          <td>Visual
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#power-leech" style={{
              "color": "rgb(87, 158, 182)"
            }}>Power Leech</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Compulsion
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 4
          </td>
          <td>Visual; see text
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#power-resistance" style={{
              "color": "rgb(87, 158, 182)"
            }}>Power Resistance</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 5
          </td>
          <td>Material and visual
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#precognition" style={{
              "color": "rgb(87, 158, 182)"
            }}>Precognition</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Seer 1
          </td>
          <td>Visual
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}><a href="psionicPowersGtoP.html#precognition-defensive" style={{
              "color": "rgb(87, 158, 182)"
            }}>Precognition, Defensive</a>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Psion/wilder 1, psychic
warrior 1
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Material and visual
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#precognition-greater" style={{
              "color": "rgb(87, 158, 182)"
            }}>Precognition, Greater</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Seer 6
          </td>
          <td>Auditory and visual
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}><a href="psionicPowersGtoP.html#precognition-offensive" style={{
              "color": "rgb(87, 158, 182)"
            }}>Precognition, Offensive</a>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Psion/wilder 1, psychic
warrior 1
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Material and visual
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#prescience-offensive" style={{
              "color": "rgb(87, 158, 182)"
            }}>Prescience, Offensive</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 1, psychic warrior 1
          </td>
          <td>Material and visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#prevenom" style={{
              "color": "rgb(87, 158, 182)"
            }}>Prevenom</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Creation
          </td>
          <td>
          </td>
          <td>Psychic warrior 1
          </td>
          <td>Material
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#prevenom-weapon" style={{
              "color": "rgb(87, 158, 182)"
            }}>Prevenom Weapon</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Creation
          </td>
          <td>
          </td>
          <td>Psychic warrior 1
          </td>
          <td>Material
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}><a href="psionicPowersGtoP.html#prowess" style={{
              "color": "rgb(87, 158, 182)"
            }}>Prowess</a>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Psychic warrior 2
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Mental
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#psionic-blast" style={{
              "color": "rgb(87, 158, 182)"
            }}>Psionic Blast</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 3
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#psionic-lions-charge" style={{
              "color": "rgb(87, 158, 182)"
            }}>Psionic Lion's Charge</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psychic warrior 2
          </td>
          <td>Mental
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#psionic-lock" style={{
              "color": "rgb(87, 158, 182)"
            }}>Psionic Lock</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 2
          </td>
          <td>Material
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#psionic-repair-damage" style={{
              "color": "rgb(87, 158, 182)"
            }}>Psionic Repair Damage</a>
          </td>
          <td>Metacreativity
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Shaper 2
          </td>
          <td>Material and visual
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#psionic-revivify" style={{
              "color": "rgb(87, 158, 182)"
            }}>Psionic Revivify</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Healing
          </td>
          <td>Good
          </td>
          <td>Egoist 5
          </td>
          <td>None
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#psionic-scent" style={{
              "color": "rgb(87, 158, 182)"
            }}>Psionic Scent</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psychic warrior 2
          </td>
          <td>Mental
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#psychic-chirurgery" style={{
              "color": "rgb(87, 158, 182)"
            }}>Psychic Chirurgery</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Telepath 9
          </td>
          <td>Auditory, mental, and visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#psychic-crush" style={{
              "color": "rgb(87, 158, 182)"
            }}>Psychic Crush</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 5
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#psychic-reformation" style={{
              "color": "rgb(87, 158, 182)"
            }}>Psychic Reformation</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 4
          </td>
          <td>Auditory, mental, and visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#psychic-vampire" style={{
              "color": "rgb(87, 158, 182)"
            }}>Psychic Vampire</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Egoist 4, psychic warrior 4
          </td>
          <td>Mental
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#psychofeedback" style={{
              "color": "rgb(87, 158, 182)"
            }}>Psychofeedback</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Egoist 5, psychic warrior 5
          </td>
          <td>Visual
          </td>
        </tr>
        <tr>
          <td rowSpan="1" colSpan="6">
            <h3><a id="q"></a>Q</h3>
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#quintessence" style={{
              "color": "rgb(87, 158, 182)"
            }}>Quintessence</a>
          </td>
          <td>Metacreativity
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Creation
          </td>
          <td>
          </td>
          <td>Shaper 4
          </td>
          <td>Material; see text
          </td>
        </tr>
        <tr>
          <td rowSpan="1" colSpan="6">
            <h3><a id="r"></a>R</h3>
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#read-thoughts" style={{
              "color": "rgb(87, 158, 182)"
            }}>Read Thoughts</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Telepath 2
          </td>
          <td>Mental
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#reality-revision" style={{
              "color": "rgb(87, 158, 182)"
            }}>Reality Revision</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 9
          </td>
          <td>See text
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#recall-agony" style={{
              "color": "rgb(87, 158, 182)"
            }}>Recall Agony</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 2
          </td>
          <td>Material
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#recall-death" style={{
              "color": "rgb(87, 158, 182)"
            }}>Recall Death</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Death, Mind-Affecting
          </td>
          <td>Psion/wilder 8
          </td>
          <td>Material
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#reddopsi" style={{
              "color": "rgb(87, 158, 182)"
            }}>Reddopsi</a>
          </td>
          <td>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Kineticist 7
          </td>
          <td>Auditory, mental, and olfactory
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#remote-view-trap" style={{
              "color": "rgb(87, 158, 182)"
            }}>Remote View Trap</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Electricity
          </td>
          <td>Psion/wilder 6
          </td>
          <td>Mental and visual
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#remote-viewing" style={{
              "color": "rgb(87, 158, 182)"
            }}>Remote Viewing</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Scrying; 
            <p>{`see text`}</p>
          </td>
          <td>
          </td>
          <td>Seer 4
          </td>
          <td>Mental
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#restoration-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Restoration, Psionic</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Healing
          </td>
          <td>
          </td>
          <td>Egoist 6
          </td>
          <td>Material
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#restore-extremity" style={{
              "color": "rgb(87, 158, 182)"
            }}>Restore Extremity</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Healing
          </td>
          <td>
          </td>
          <td>Egoist 5
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#retrieve" style={{
              "color": "rgb(87, 158, 182)"
            }}>Retrieve</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Teleportation
          </td>
          <td>
          </td>
          <td>Psion/wilder 6
          </td>
          <td>Visual
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }} rowSpan="1" colSpan="6">
            <h3><a id="s"></a>S</h3>
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#schism" style={{
              "color": "rgb(87, 158, 182)"
            }}>Schism</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Telepath 4
          </td>
          <td>Auditory and visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#second-chance" style={{
              "color": "rgb(87, 158, 182)"
            }}>Second Chance</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Seer 5
          </td>
          <td>Mental
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#sense-link" style={{
              "color": "rgb(87, 158, 182)"
            }}>Sense Link</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 1
          </td>
          <td>Visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#sense-link-forced" style={{
              "color": "rgb(87, 158, 182)"
            }}>Sense Link, Forced</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 2
          </td>
          <td>Visual
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#sensitivity-to-psychic-impressions" style={{
              "color": "rgb(87, 158, 182)"
            }}>Sensitivity to Psychic Impressions</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Seer 2
          </td>
          <td>Auditory and material
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#sequester-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Sequester, Psionic</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 7
          </td>
          <td>None
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#shadow-body" style={{
              "color": "rgb(87, 158, 182)"
            }}>Shadow Body</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 8
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#share-pain" style={{
              "color": "rgb(87, 158, 182)"
            }}>Share Pain</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 2
          </td>
          <td>Material and mental
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#share-pain-forced" style={{
              "color": "rgb(87, 158, 182)"
            }}>Share Pain, Forced</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 3
          </td>
          <td>Material and mental
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#shatter-mind-blank" style={{
              "color": "rgb(87, 158, 182)"
            }}>Shatter Mind Blank</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 5
          </td>
          <td>Olfactory
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#skate" style={{
              "color": "rgb(87, 158, 182)"
            }}>Skate</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 1, psychic warrior 1
          </td>
          <td>Material and visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#solicit-psicrystal" style={{
              "color": "rgb(87, 158, 182)"
            }}>Solicit Psicrystal</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 3
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#steadfast-perception" style={{
              "color": "rgb(87, 158, 182)"
            }}>Steadfast Perception</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psychic warrior 4
          </td>
          <td>Visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#stomp" style={{
              "color": "rgb(87, 158, 182)"
            }}>Stomp</a>
          </td>
          <td>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psychic warrior 1
          </td>
          <td>Auditory and visual
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#strength-of-my-enemy" style={{
              "color": "rgb(87, 158, 182)"
            }}>Strength of my Enemy</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psychic warrior 2
          </td>
          <td>Visual; see text
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#suggestion-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Suggestion, Psionic</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>Compulsion
          </td>
          <td>Mind-Affecting, Language-Dependent
          </td>
          <td>Telepath 2
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#suspend-life" style={{
              "color": "rgb(87, 158, 182)"
            }}>Suspend Life</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 6, psychic warrior 6
          </td>
          <td>Olfactory
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#sustenance" style={{
              "color": "rgb(87, 158, 182)"
            }}>Sustenance</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 2, psychic warrior 2
          </td>
          <td>Material
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#swarm-of-crystals" style={{
              "color": "rgb(87, 158, 182)"
            }}>Swarm of Crystals</a>
          </td>
          <td>Metacreativity
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Creation
          </td>
          <td>
          </td>
          <td>Psion/wilder 2
          </td>
          <td>Material
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#synesthete" style={{
              "color": "rgb(87, 158, 182)"
            }}>Synesthete</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 1, psychic warrior 1
          </td>
          <td>Material
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }} rowSpan="1" colSpan="6">
            <h3><a id="t"></a>T</h3>
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#telekinetic-force" style={{
              "color": "rgb(87, 158, 182)"
            }}>Telekinetic Force</a>
          </td>
          <td>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Force
          </td>
          <td>Psion/wilder 3
          </td>
          <td>Visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#telekinetic-maneuver" style={{
              "color": "rgb(87, 158, 182)"
            }}>Telekinetic Maneuver</a>
          </td>
          <td>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Force
          </td>
          <td>Psion/wilder 4
          </td>
          <td>Visual
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#telekinetic-sphere-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Telekinetic Sphere, Psionic</a>
          </td>
          <td>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Force
          </td>
          <td>Kineticist 8
          </td>
          <td>Material
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#telekinetic-thrust" style={{
              "color": "rgb(87, 158, 182)"
            }}>Telekinetic Thrust</a>
          </td>
          <td>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 3
          </td>
          <td>Visual
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#telempathic-projection" style={{
              "color": "rgb(87, 158, 182)"
            }}>Telempathic Projection</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Charm
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 1
          </td>
          <td>Visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#teleport-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Teleport, Psionic</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Teleportation
          </td>
          <td>
          </td>
          <td>Nomad 5
          </td>
          <td>Visual
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#teleport-psionic-greater" style={{
              "color": "rgb(87, 158, 182)"
            }}>Teleport, Psionic Greater</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Teleportation
          </td>
          <td>
          </td>
          <td>Psion/wilder 8
          </td>
          <td>Visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#teleportation-circle-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Teleportation Circle, Psionic</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Teleportation
          </td>
          <td>
          </td>
          <td>Nomad 9
          </td>
          <td>Mental
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#teleport-trigger" style={{
              "color": "rgb(87, 158, 182)"
            }}>Teleport Trigger</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Teleportation
          </td>
          <td>
          </td>
          <td>Nomad 5
          </td>
          <td>Material
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#temporal-acceleration" style={{
              "color": "rgb(87, 158, 182)"
            }}>Temporal Acceleration</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 6
          </td>
          <td>None
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#thicken-skin" style={{
              "color": "rgb(87, 158, 182)"
            }}>Thicken Skin</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Egoist 1, psychic warrior 1
          </td>
          <td>Material and olfactory
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}><a href="psionicPowersQtoW.html#thought-shield" style={{
              "color": "rgb(87, 158, 182)"
            }}>Thought Shield</a>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Mind-Affecting
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Psion/wilder 2, psychic
warrior 2
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Auditory
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#time-hop" style={{
              "color": "rgb(87, 158, 182)"
            }}>Time Hop</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 3
          </td>
          <td>Auditory and visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#time-hop-mass" style={{
              "color": "rgb(87, 158, 182)"
            }}>Time Hop, Mass</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Nomad 8
          </td>
          <td>Auditory and visual
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#time-regression" style={{
              "color": "rgb(87, 158, 182)"
            }}>Time Regression</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Nomad 9
          </td>
          <td>None
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}><a href="psionicPowersQtoW.html#timeless-body" style={{
              "color": "rgb(87, 158, 182)"
            }}>Timeless Body</a>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Psion/wilder 9
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Material
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#tongues-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Tongues, Psionic</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 2
          </td>
          <td>None
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#tornado-blast" style={{
              "color": "rgb(87, 158, 182)"
            }}>Tornado Blast</a>
          </td>
          <td>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Kineticist 9
          </td>
          <td>Auditory and visual; see text
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#touchsight" style={{
              "color": "rgb(87, 158, 182)"
            }}>Touchsight</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 3
          </td>
          <td>Visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#tower-of-iron-will" style={{
              "color": "rgb(87, 158, 182)"
            }}>Tower of Iron Will</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 5
          </td>
          <td>Auditory
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#trace-teleport" style={{
              "color": "rgb(87, 158, 182)"
            }}>Trace Teleport</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 4
          </td>
          <td>Visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#true-creation" style={{
              "color": "rgb(87, 158, 182)"
            }}>True Creation</a>
          </td>
          <td>Metacreativity
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Creation
          </td>
          <td>
          </td>
          <td>Shaper 9
          </td>
          <td>None
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#true-metabolism" style={{
              "color": "rgb(87, 158, 182)"
            }}>True Metabolism</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 8
          </td>
          <td>Material
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}><a href="psionicPowersQtoW.html#true-seeing-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>True Seeing, Psionic</a>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Psion/wilder 5
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Visual
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#truevenom" style={{
              "color": "rgb(87, 158, 182)"
            }}>Truevenom</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psychic warrior 4
          </td>
          <td>Material; see text
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#truevenom-weapon" style={{
              "color": "rgb(87, 158, 182)"
            }}>Truevenom Weapon</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Creation
          </td>
          <td>
          </td>
          <td>Psychic warrior 4
          </td>
          <td>Material; see text
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }} rowSpan="1" colSpan="6">
            <h3><a id="u"></a>U</h3>
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#ubiquitous-vision" style={{
              "color": "rgb(87, 158, 182)"
            }}>Ubiquitous Vision</a>
          </td>
          <td>Clairsentience
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 3, psychic warrior 3
          </td>
          <td>Visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#ultrablast" style={{
              "color": "rgb(87, 158, 182)"
            }}>Ultrablast</a>
          </td>
          <td>Telepathy
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>Mind-Affecting
          </td>
          <td>Psion/wilder 7
          </td>
          <td>Auditory; see text
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }} rowSpan="1" colSpan="6">
            <h3><a id="v"></a>V</h3>
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#vampiric-blade" style={{
              "color": "rgb(87, 158, 182)"
            }}>Vampiric Blade</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psychic warrior 3
          </td>
          <td>Material and visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#vigor" style={{
              "color": "rgb(87, 158, 182)"
            }}>Vigor</a>
          </td>
          <td>Psychometabolism
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psion/wilder 1, psychic warrior 1
          </td>
          <td>Material and olfactory
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }} rowSpan="1" colSpan="6">
            <h3><a id="w"></a>W</h3>
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#wall-of-ectoplasm" style={{
              "color": "rgb(87, 158, 182)"
            }}>Wall of Ectoplasm</a>
          </td>
          <td>Metacreativity
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Creation
          </td>
          <td>
          </td>
          <td>Psion/wilder 4
          </td>
          <td>Visual
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#wall-walker" style={{
              "color": "rgb(87, 158, 182)"
            }}>Wall Walker</a>
          </td>
          <td>Psychoportation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>
          </td>
          <td>Psychic warrior 2
          </td>
          <td>Material
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#weapon-of-energy" style={{
              "color": "rgb(87, 158, 182)"
            }}>Weapon of Energy</a>
          </td>
          <td>Psychokinesis
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td>see text
          </td>
          <td>Psychic warrior 4
          </td>
          <td>Visual
          </td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      